export default class PaymentsHelper {

  // Method to format date from 'DD-MMM-YY' to 'Month-YYYY'
  static formatDate(inputDate) {
    // Validate the input format: expects 'DD-MMM-YY'
    if (!inputDate || !/^\d{2}-[A-Z]{3}-\d{2}$/.test(inputDate)) {
      throw new Error('Invalid date format. Expected format: DD-MMM-YY');
    }

    // Split the input date string into day, month abbreviation, and year
    const [, monthAbbr, year] = inputDate.split('-');

    // Create a mapping of month abbreviations to full month names
    const monthMap = {
      'JAN': 'January', 'FEB': 'February', 'MAR': 'March', 'APR': 'April',
      'MAY': 'May', 'JUN': 'June', 'JUL': 'July', 'AUG': 'August',
      'SEP': 'September', 'OCT': 'October', 'NOV': 'November', 'DEC': 'December'
    };

    // Get the full month name from the abbreviation
    const monthName = monthMap[monthAbbr];
    if (!monthName) {
      throw new Error(`Invalid month abbreviation: ${monthAbbr}`);
    }

    // Return the formatted date as 'Month-YYYY'
    return `${monthName}-20${year}`;
  }

  // Method to extract and format the exam date
  static examDate = (item) => {
    const examMatch = item.name.match(/Exam date: ([\dA-Z-]+)/);  // Matches 'Exam date: DATE'

    if (!examMatch) return '';  // Return empty if no match found

    let examDate = `Exam date: ${examMatch[1]}`;  // Default output without formatting

    // If 'QFA' is in the name, format the date
    if (item.name.includes("QFA")) {
      try {
        const formattedDate = this.formatDate(examMatch[1]);  // Format the matched date
        examDate = `Exam date ${formattedDate}`;  // Set formatted date
      } catch (e) {
        console.error(e.message);  // Log any formatting errors
      }
    }

    return examDate;
  }

  // Method to extract and format the exam venue
  static examVenue = (item) => {
    // Match the venue in the form 'Exam Venue: LOCATION<br>'
    const venueMatch = item.attributes.venue.match(/Exam Venue: (?<exam_venue>[\w\s]*)<br>/);

    // Return formatted venue if match found, else return empty string
    return venueMatch ? ` - Exam Location: ${venueMatch.groups.exam_venue}` : '';
  }

  static findToggleIndex = (targets, index) => targets.find((toggle) => toggle.dataset.index === index)

  static employerUserChecked = (currentEl, disclosureEl, employerEl) => currentEl === disclosureEl && disclosureEl.checked && employerEl.checked
}
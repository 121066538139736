import { Controller } from 'stimulus';
import PaymentsHelper from '../helper-classes/payments_helper';
import toastr from 'toastr';

import { showLoader, hideLoader } from './loader_controller';

export default class extends Controller {
  static targets = [
    'cartIdInput',
    'cartItemTemplate',
    'cartItems',
    'container',
    'disclosureToggle',
    'employerToggle',
    'orderCost',
    'orderID',
    'itemIds',
    'redirectURL',
    'slaBlock',
    'slaDeclarationText',
    'submitButton',
    'totalEmployer',
    'totalUser',
    'checkoutPage',
    'user'
  ];

  get newCartItem() {
    return this.cartItemTemplateTarget.innerHTML;
  }

  set totalUserCost(cost) {
    this.totalUserTarget.innerText = `€ ${cost}`;
    this.totalUserTarget.dataset.total = cost;
  }

  get totalUserCost() {
    return parseFloat(this.totalUserTarget.dataset.total);
  }

  set totalEmployerCost(cost) {
    this.totalEmployerTarget.innerText = `€ ${cost}`;
    this.totalEmployerTarget.dataset.total = cost;
  }

  get totalEmployerCost() {
    return parseFloat(this.totalEmployerTarget.dataset.total);
  }

  connect(){
    if(this.hasUserTarget){
      showLoader();
      if((document.getElementById('payments_terms_and_conditions')))
        document.getElementById('payments_terms_and_conditions').disabled = true;
      fetch('/events/event_details', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: this.userTarget.dataset.user_id })
      })
      .then(response => response.json())
      .then((data) => {
        if (data['errors']) {
          toastr.error(data['errors']['text'], 'Error', {
            timeOut: 0,
            extendedTimeOut: 0,
            closeButton: true,
            allowHtml: true,
            positionClass: "toast-top-right"
          });
        }
        else
          this.showCart(data.cart)
        hideLoader();
        if(document.getElementById('payments_terms_and_conditions'))
          document.getElementById('payments_terms_and_conditions').disabled = false;
      })
    }
  }

  togglePayment({ currentTarget }) {
    const { index } = currentTarget.dataset;
    const employerToggle = PaymentsHelper.findToggleIndex(this.employerToggleTargets, index);
    const disclosureToggle = PaymentsHelper.findToggleIndex(this.disclosureToggleTargets, index);
    const cost = parseFloat(employerToggle.dataset.cost);
    const { sla } = employerToggle.dataset;
    const toggleMsg = document.querySelector(`.toggleMsg_${index}`).parentElement;

    if (disclosureToggle && currentTarget === employerToggle && employerToggle.checked) {
      disclosureToggle.checked = false;
    }

    if (PaymentsHelper.employerUserChecked(currentTarget, disclosureToggle, employerToggle)) {
      employerToggle.checked = false;
      toggleMsg.style.display = 'none';
      this.addToUserCost(cost, sla);
    }

    this.checkEmployerToggle(currentTarget, employerToggle, cost, sla);
  }

  checkEmployerToggle(currentTarget, employerToggle, cost, sla) {
    if (currentTarget === employerToggle) {
      if (employerToggle.checked) {
        this.addToEmployerCost(cost, sla);
      } else {
        this.addToUserCost(cost, sla);
      }
    }
  }

  addToUserCost(cost, sla) {
    this.totalUserCost += cost;
    this.totalEmployerCost -= cost;

    if (sla !== '') {
      this.slaText.splice(this.slaText.indexOf(sla), 1);
      this.displaySlaText();
    }
  }

  addToEmployerCost(cost, sla) {
    this.totalUserCost -= cost;
    this.totalEmployerCost += cost;
    this.appendSLAText(sla);
  }

  showCart(cart) {
    this.clearSLAText();
    this.cartVariables();
    const itemIds = cart.items.map(({item_id}) => item_id).toString();
    this.itemIdsTarget.value = itemIds;
    cart.items.forEach((item, index) => {
      let slaDeclaration = '';
      let authentication = false;
      const skipDeclerationBank = 'Ulster Bank';
      const slaPayment = item.options.payment.find((paymentOption) => paymentOption.payment_type === 'sla');
      if (slaPayment) {
        this.totalEmployerCost += parseFloat(item.cost);
        slaDeclaration = encodeURI(slaPayment.declaration_text);
        authentication = slaPayment.authentication_code === '1';
        if (slaPayment.declaration_text) this.appendSLAText(slaDeclaration); }
      const newCartItemText = this.newCartItemText(item, index, slaDeclaration);
      const newCartItemElement = document.createElement('div');
      newCartItemElement.innerHTML = newCartItemText;
      if (!slaPayment) {
        this.totalUserCost += parseFloat(item.cost);
        newCartItemElement.querySelector('.general-payment-card__toggle').classList.add('display-none');
      } else { newCartItemElement.querySelector(`input#payments_membership_employer_paying_${item.item_id}`).checked = true; }
      if ((slaDeclaration.includes(skipDeclerationBank) && authentication) || (!slaDeclaration.includes(skipDeclerationBank) && authentication)) {
        newCartItemElement.querySelector('.js-authentication').classList.remove('display-none');
      }
      if (!['modules', 'rereg', 'onlinedesignation'].includes(item.category)) {
        const discloseItem = newCartItemElement.querySelector('.js-disclosure');
        discloseItem.parentElement.removeChild(discloseItem);
      }
      this.cartItemsTarget.appendChild(newCartItemElement);
    });
    this.cartIdInputTarget.value = cart.id;
  }

  gaBeginCheckout(cart){
    let totalCost = 0
    for(let i = 0; cart.items[i]; i++) {
        totalCost += Number(cart.items[i].cost)
    }
    dataLayer.push({ ecommerce: null });
      dataLayer.push({
        'event': 'begin_checkout',
        'event_category': 'Enhanced Ecommerce',
        'event_action': 'Checkout',
        'event_label': 'Step 1: Personal',
        'programme_name': this.redirectURLTarget.dataset.name,
        'ecommerce': {
            'currencyCode': 'EUR',
            'checkout': {
              'actionField': { 'step': 1, 'option': 'Personal' },
              'products': [{
                'name': this.redirectURLTarget.dataset.pname,
                'id': this.redirectURLTarget.dataset.programid,
                'price': `${totalCost}`,
                'brand': 'IOB',
                'category': this.redirectURLTarget.dataset.category,
                'variant': this.redirectURLTarget.dataset.variant,
                'quantity': this.redirectURLTarget.dataset.quantity,
              }]
            }
          }
      });
  }

  fetchCart({ target }) {
    showLoader();
    target.style.visibility = 'hidden';
    fetch('/forms_api/cart')
      .then((response) => response.json())
      .then(data => {
        hideLoader();
        target.style.visibility = null;
        this.gaBeginCheckout(data.cart)
        this.showCart(data.cart)
      });
  }

  cartVariables() {
    this.totalEmployerCost = 0;
    this.totalUserCost = 0;
    this.slaText = [];
    this.cartItemsTarget.innerHTML = '';
  }

  newCartItemText(item, index, slaDeclaration) {
    const displayName = ['membership', 'subrenewal', 'cpdrenewal', 'events'].includes(item.category);
    const validUntil = ['membership', 'subrenewal', 'cpdrenewal'].includes(item.category);

    const newCartItemText = this.newCartItem
      .replace(/TEMPLATE_NAME/g, displayName ? item.name : item.description)
      .replace(/TEMPLATE_COST/g, item.cost)
      .replace(/TEMPLATE_PRODUCT_ID/g, item.product_id)
      .replace(/TEMPLATE_ITEM_ID/g, item.item_id)
      .replace(/TEMPLATE_VALID/g, validUntil ? `Valid until 31-DEC-${new Date().getYear() - 100}` : '')
      .replace(/TEMPLATE_EXAM_DATE/g, PaymentsHelper.examDate(item))
      .replace(/TEMPLATE_EXAM_VENUE/g, PaymentsHelper.examVenue(item))
      .replace(/TEMPLATE_INDEX/g, index)
      .replace(/TEMPLATE_OPTIONS_ID/g, encodeURIComponent(JSON.stringify(item.options.payment)))
      .replace(/TEMPLATE_SLA/g, slaDeclaration);
    return newCartItemText;
  }

  appendSLAText(text) {
    if (!text) return;

    this.slaText.push(text);
    this.displaySlaText();
  }

  clearSLAText() {
    this.slaText = [];
    this.displaySlaText();
  }

  displaySlaText() {
    const blockItems = this.slaText.reduce((items, item) => {
      const newItem = decodeURI(item);
      if (!items.includes(newItem)) items.push(newItem);
      return items;
    }, []);
    const declaration = this.slaBlockTarget.querySelector('.sla-declaration');

    if (blockItems.length > 0) {
      declaration.innerHTML = blockItems.join('<br><br>');
      this.slaBlockTarget.classList.remove('display-none');
    } else {
      declaration.innerHTML = '';
      this.slaBlockTarget.classList.add('display-none');
    }
  }

  checkScroll() {
    const employerToggle = document.querySelector('.employer-toggle');
    employerToggle.classList.remove('toggle--disabled');
  }

  fetchOrderInfo({ target}) {
    showLoader();
    target.style.visibility = 'hidden';
    fetch('/forms_api/order')
      .then(response => response.json())
      .then(({ content, order }) => {
        hideLoader();
        target.style.visibility = null;
        if (content) {
          this.checkoutPageTarget.innerHTML = content;
        } else {
          this.orderIDTarget.innerText = order.order_id;
          this.redirectURLTarget.href = order.url;
        }
        this.orderCostTarget.innerText = this.totalUserCost;
      });
  }

  gaPayment(){
    let title
    if(this.submitButtonTarget.dataset.gaCheck == 'event')
      title = "Payments Checkout Tab"
    else
      title = this.submitButtonTarget.dataset.title
      dataLayer.push({
        'event': 'enrollments_funnel',
        'event_category': 'Enrollments',
        'event_action': 'Enrolled: ' + this.submitButtonTarget.dataset.name,
        'event_label': title,
        'programme_name': this.submitButtonTarget.dataset.name,
        'enrollments_step': title
      });
  }
}
